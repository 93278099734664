import {inject} from "aurelia-framework";
import {Client} from "../api/client";

import "./erv-overview.less";

@inject(Client)
export class ErvOverview {
    conditions = {};

    tabConfig = [
        {
            heading: 'erv-overview.tab.insurance-orders',
            content: '<sio-table\n' +
            '                config="export/erv-insurance-order"\n' +
            '            ></sio-table>'
        }
    ];
}
