import {bindable} from 'aurelia-framework';

export class ThumbnailsSidebar {
    @bindable documents;
    @bindable onSelect;
    @bindable selectedIndex;

    getIcon(document)
    {
        if (!document.usedInActions || document.usedInActions.length === 2) {
            return null;
        }

        if (document.usedInActions.indexOf('email') > -1) {
            return 'sio-icon-message glyphicon';
        }

        if (document.usedInActions.indexOf('print') > -1) {
            return 'sio-icon-printer glyphicon';
        }

        return null;
    }
}
