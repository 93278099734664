import {bindable} from "aurelia-templating";

export class DocumentsPreview {
    @bindable documents;

    selectedIndex = undefined;

    selectDocument = (selectedIndex) => {
        this.selectedIndex = selectedIndex;
    }

    documentsChanged() {
        if ((this.documents || []).length > 0) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex = undefined;
        }
    }
}
