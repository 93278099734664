import {inject, observable} from "aurelia-framework";
import {DialogController} from 'aurelia-dialog';
import {Client} from "../api/client";

import "./documents-preview-dialog.less";
import {WorkflowService} from "../workflow/workflow-service";
import {ConfigurationLoader} from "../form/loader/configuration-loader";
import * as _ from "lodash";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import printJS from "print-js";
import {SubmitErrorHandler} from "../form/submit-error-handler";

@inject(
    Client,
    EventAggregator,
    FlashService,
    ConfigurationLoader,
    FormServiceFactory,
    DialogController,
)
export class DocumentsPreviewDialog {

    documents = [];
    selectedTab = 'documents';
    loading = false;

    objects = [];
    formData = [];
    formErrors = [];
    currentObject;
    currentIndex;

    errors = [];
    warnings = [];

    presets = {
        regular: {
            title: 'sio.documents_preview.title',
            tabs: [
                'email',
                'documents'
            ],
            active: 'documents',
            previewActions: [
                'email',
                'save',
                'print'
            ],
            saveLabel: 'document-generator.save',
            previousEmailLabel: 'document-generator.email-again',
            emailLabel: 'document-generator.email',
            previousPrintLabel: 'document-generator.print-again',
            printLabel: 'document-generator.print',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        email: {
            title: 'email.send-email',
            tabs: [
                'email'
            ],
            active: 'email',
            previewActions: [
                'email',
            ],
            emailLabel: 'email.send-email',
            emailsTab: 'E-Mail',
            documentsTab: 'Dokumente'
        },
        letter: {
            title: 'document-generator.letter',
            tabs: [
                'email',
                'documents'
            ],
            active: 'email',
            previewActions: [
                'print',
            ],
            printLabel: 'document-generator.send-letter',
            emailsTab: 'Brief',
            documentsTab: 'Vorschau'
        },
        document: {
            title: 'document-generator.document',
            tabs: [
                'documents'
            ],
            active: 'documents',
            previewActions: [
                'print',
            ],
            printLabel: 'document-generator.print-document',
            emailsTab: 'E-Mail',
            documentsTab: 'Vorschau'
        }
    };

    closeDialog = () => {
        this.dialogController.ok();
    }

    constructor(
        client,
        ea,
        flashService,
        configurationLoader,
        formServiceFactory,
        dialogController
    ) {
        this.client = client;
        this.ea = ea;
        this.flashService = flashService;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
        this.dialogController = dialogController;
    }

    async activate(context) {

        console.log('Context', _.cloneDeep(context));

        this.preset = context.previewPreset || 'regular';
        this.settings = this.presets[this.preset];
        this.selectedTab = this.settings.active;

        //Preselect form case
        if (context?.additionalData?.objects) {
            this.objects = [];

            for (let objectId of context?.additionalData?.objects) {
                let splits = objectId.split('-');

                let modelId = splits[0];

                if (splits.length > 2) {
                    modelId = splits[0] + '-' + splits[1];
                }

                this.objects.push({
                    id: splits[splits.length - 1],
                    modelId: modelId,
                });
            }
            delete context?.additionalData?.objects;

        //Bulk workflow case
        } else if (typeof context.id !== 'string') {
            this.objects = [];

            for (let objectId of context.id) {
                this.objects.push({
                    id: objectId,
                    modelId: context.modelId,
                });
            }

        } else {
            this.objects = [{
                id: context.id,
                modelId: context.modelId,
            }];
        }

        this.context = context;
        this.title = context.title || this.settings.title;

        await this.selectObject(0, true);
    }

    async selectObject(index, initial) {

        if (this.formService) {
            this.formData[this.currentIndex] = this.formService.getValue();
        }

        this.currentObject = this.objects[index];
        this.currentIndex = index;
        this.documents = [];

        let data = Object.assign(
            {
                object: this.currentObject,
                type: this.context.previewGeneratorId,
                previousVersion: this.context.previewPrevious,
            }, this.context.additionalData || {});

        let config = await this.configurationLoader.get('document-generator/preview-options', data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data), this.currentObject);
        this.formService.changeCallback = this.formValueChanged.bind(this);

        if (this.formData[index]) {
            this.formService.setValue(this.formData[index]);
        }

        if (this.formErrors[index]) {
            this.formService.setErrors(this.formErrors[index]);
        }

        await this.preview(initial);
    }

    selectTab(tab) {
        this.selectedTab = tab;
    }

    getFields(formService) {
        let fields = [];

        for (let field of formService.config.fields) {
            if (field.property === 'emailSubject' || field.property === 'emailContent' || field.hidden === true) {
                continue;
            }

            if (field.property === 'printerSelection' && this.settings.previewActions.indexOf('print') === -1) {
                continue;
            }

            if (field.property === 'emailReceiver' && this.settings.previewActions.indexOf('email') === -1) {
                continue;
            }

            fields.push(field);
        }

        return fields;
    }

    formValueChanged(field) {

        if (this.preset === 'letter' && (field.property === 'emailSubject' || field.property === 'emailContent')) {
            this.showPreview = true;
        }

        if (field.options && field.options.preview) {
            this.showPreview = true;
        }
    }

    async preview(initial) {
        this.loading = true;
        this.showPreview = false;
        this.errors = [];
        this.warnings = [];

        try {
            let data = (await this.client.patch(
                'document-generation',
                [
                    Object.assign({}, this.formService.getValue(), {action: 'preview'})
                ]
            )).data;

            this.documents = data[0].documents;
            this.errors = data[0].errors ?? [];
            this.warnings = data[0].warnings ?? [];

            let value = this.formService.getValue();

            if (this.preset !== 'letter') {
                value.emailContent = data[0].emailContent;
                value.emailSubject = data[0].emailSubject;
            }

            this.formService.setValue(value);

        } catch (err) {
            this.handleErrors(err, initial);
        } finally {
            this.loading = false;
        }
    }

    async send(action) {

        this.submitting = true;

        this.formData[this.currentIndex] = this.formService.getValue();

        let requests = [];

        for (let i = 0; i < this.objects.length; i++) {

            requests.push(Object.assign({
                    object: this.objects[i],
                    type: this.context.previewGeneratorId,
                    previousVersion: this.context.previewPrevious
                },
                this.formData[i] || {},
                {action: action}));
        }

        try {
            let data = (await this.client.patch(
                'document-generation',
                requests
            )).data;

            if (action === 'print' && data?.[0]?.url) {
                //Todo multiple documents for printing
                printJS({printable: data[0].url, type: 'pdf'});

                this.flashService.success('document-generator.finished');
            } else {
                this.flashService.success('document-generator.running_in_background');
            }

            this.closeDialog();

            ['file/file', 'payment/payment'].forEach(modelId => {
                this.ea.publish('sio_form_post_submit', {config: {modelId}});
            });

        } catch (err) {
            console.log('ERR', err);

            this.handleErrors(err);
        } finally {
            this.submitting = false;
        }
    }

    hasErrors(errors) {
        if (!errors || !errors.children) {
            return false;
        }

        let hasErrors = false;

        _.forEach(errors.children, (element, key) => {
            if (element.errors && element.errors.length > 0) {
                hasErrors = true;
            }
        });

        return hasErrors;
    }

    handleErrors(err, initial) {
        if (err?.data?.errors) {

            this.formErrors = err.data.errors;

            if (this.formErrors[this.currentIndex]) {
                this.formService.resetErrors(true);
                this.formService.setErrors(this.formErrors[this.currentIndex]);
            }
        }

        let message = null;

        if (err?.data?.localizedMessage) {
            message = err.data.localizedMessage;
        } else if (403 === err.status) {
            message = 'sio.access_denied';
        } else if (400 !== err.status) {
            message = this.formService.rootConfig.labels.communicationError;
        } else {
            message = this.formService.rootConfig.labels.validationError;
        }

        if (message) {
            if (initial) {
                throw {data: {message: message}};
            } else {
                this.flashService.error(message);
            }
        }
    }
}
